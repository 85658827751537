import React from 'react';

import { Footer, Blog, Possibility, Features, WhatGPT3, Header } from './containers';
import WppIcon from './assets/wppicon.png'
import { CTA, Brand, Navbar } from './components';

import './App.css';
import ShopSection from './components/shop/Shop';

const App = () => (
  <div className="App">
    <div className="gradient__bg">
      <Navbar />
      <Header />
    </div>
    <Brand />
    <WhatGPT3 />
    <ShopSection/>
    <Features />
    <Possibility />
    <CTA />
    <Blog />
    <Footer />
      {/* WhatsApp Button */}
      <div className="whatsapp-button">
      <a href="https://wa.me/5491125071506?text=Hola!%20Quiero%20hablar%20con%20un%20asesor" target="_blank">
        <img src={WppIcon} alt="WhatsApp Icon" />
      </a>
    </div>
  </div>
);

export default App;

import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="Quienes somos" text="En LionSeg  nos enorgullecemos de ofrecer soluciones avanzadas en seguridad y protección para tu hogar o negocio. Somos una empresa especializada en la instalación y configuración de sistemas de cámaras de seguridad y alarmas, diseñados para brindarte tranquilidad y protección las 24 horas del día, los 7 días de la semana." />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__light">Mas de 15 años brindando seguridad integral</h1>
      <p></p>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="Sistema de alarmas" text="Protección y seguridad 24/7 para tu hogar y negocio con nuestro sistema de alarmas avanzado." />
      <Feature title="Videovigilancia" text="Ojos en todas partes para tu seguridad. seguridad y monitoreo en tiempo real para tu protección." />
      <Feature title="Videoverificacion" text="Ante una emergencia (disparo de alarma) un operador revisará las cámaras de seguridad respondiendo de manera efectiva ante cualquier necesidad de emergencia. " />
    </div>
  </div>
);

export default WhatGPT3;

import React from 'react';
import possibilityImage from '../../assets/possibility.png';
import './possibility.css';
import { motion } from 'framer-motion';
import { fadeIn } from '../../variants'
import { TypeAnimation } from 'react-type-animation'; 


const Possibility = () => (
  <div className="gradient__bg3  gpt3__possibility section__padding" id="possibility">
    <motion.div 
      variants={fadeIn('right', 0.3)}
      initial='hidden'
      whileInView={'show'}
      viewport={{once: false, amount: 0.3}}
    className="gpt3__possibility-image">
      <img src={possibilityImage} alt="possibility" />
    </motion.div>
    <motion.div
    variants={fadeIn('left', 0.3)}
    initial='hidden'
    whileInView={'show'}
    viewport={{once: false, amount: 0.3}}
     className="gpt3__possibility-content"
     >
    <TypeAnimation sequence={[
            'Protege lo que amas', 
            3000,
          ]}
          speed={10}
          wrapper='span'
          repeat={Infinity}
          className='subtitle'
          />
      <h1 className="gradient__text">Tu familia y negocio,<br /> siempre protegidos</h1>
      <p>Nuestra oferta de Alarmas Monitoreadas brinda la posibilidad de salvaguardar tanto su residencia como su empresa mediante la implementación de tecnologías de vanguardia. Nuestro equipo de expertos desarrolla un plan de implementación personalizado, asegurando la selección precisa de equipos acorde a sus requerimientos. Adicionalmente, un grupo de especialistas altamente competentes supervisa continuamente su propiedad y, en situaciones de urgencia, orquesta una respuesta inmediata a través de la coordinación con fuerzas de seguridad y servicios de asistencia.</p>
      <h4>Comenza hoy</h4>
    </motion.div>
  </div>
);

export default Possibility;

import React from 'react';
import './cta.css';
import { TypeAnimation } from 'react-type-animation'; 
import { useInView } from 'react-intersection-observer';



const CTA = () => {
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  return(
    <div className="gpt3__cta">
    <div className="gpt3__cta-content" ref={inViewRef}>
      <p>Contactate con uno de nuestros</p>
    
      {inView && (
        <TypeAnimation 
          sequence={[
            'Protege lo que más importa, hace tu consulta facil y rapido.', 
            1000,
          ]}
          speed={60}
          wrapper='h3'
          repeat={Infinity}
          className=''
        />
      )}
    </div>
    <div className="gpt3__cta-btn">
      <a href="#footer" type="button">Consultar</a>
    </div>
  </div>
);
}

export default CTA;

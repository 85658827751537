import React from 'react';
import './shop.css';
import ImageOne from "./Untitled image (17).jpeg";
import ImageTwo from "./Untitled image (18).jpeg";
import ImageThree from "./img.jpeg";

export default function ShopSection() {
  return (
    <section className="shop-section">
      <h2 className="gradient__text shop-title">Nuestros Productos Destacados</h2>
      <div className="shop-items">
        <div className="shop-item">
          <img src={ImageOne} alt="Producto 1" />
          <h3>Cámara de Seguridad HD</h3>
          <p>Protección de alta calidad con visión nocturna.</p>
          <a href="https://lionseg-ecommerce.vercel.app/" className="cta-button">Ir a la Tienda</a>
        </div>
        <div className="shop-item">
          <img src={ImageTwo} alt="Producto 2" />
          <h3>Camaras 360º y Alarmas</h3>
          <p>Alarma fácil de instalar para máxima seguridad.</p>
          <a href="https://lionseg-ecommerce.vercel.app/" className="cta-button">Ir a la Tienda</a>
        </div>
        <div className="shop-item">
          <img src={ImageThree} alt="Producto 3" />
          <h3>Kit de Cámaras de Vigilancia</h3>
          <p>Monitoreo completo con rotación en tiempo real.</p>
          <a href="https://lionseg-ecommerce.vercel.app/" className="cta-button">Ir a la Tienda</a>
        </div>
      </div>
    </section>
  );
}

import React from 'react';
import './features.css';
import { motion } from 'framer-motion';
import { fadeIn } from '../../variants'
import FeatureTwo from '../../components/feature/FeatureTwo';

const featuresData = [
  {
    title: 'Vigilancia 24/7',
    text: 'Monitoree sus cámaras desde cualquier PC o Smartphone con acceso a internet sin importar donde se encuentre!',
  },
  {
    title: 'Grabación de video',
    text: 'Acceda facilmente a las grabaciones de nuestras camaras de seguridad',
  },
  {
    title: ' Captura de imagen de alta calidad',
    text: 'Disponemos de cámaras de alta calidad. Capturan imágenes y videos con una claridad y definición sobresalientes, lo que garantiza la obtención de datos visuales precisos y detallados.',
  },
  {
    title: 'Respuesta Inmediata ante Emergencias',
    text: 'Accederás a una asistencia inmediata provista por los cuerpos de seguridad, los servicios de bomberos y/o las unidades médicas de emergencia, en función de las necesidades particulares',
  },
];

const Features = () => (
  <div className="gradient__bg2 gpt3__features section__padding" id="features">
    <motion.div
        variants={fadeIn('right', 0.3)}
        initial='hidden'
        whileInView={'show'}
        viewport={{once: false, amount: 0.3}}
       className="gpt3__features-heading">
      <h1 className="gradient__text">Descubre la tranquilidad con LionSeg. Experiencia, confiabilidad y tecnología a tu servicio.</h1>
      <p>Protege lo que más importa</p>
    </motion.div>
    <motion.div 
        variants={fadeIn('left', 0.3)}
        initial='hidden'
        whileInView={'show'}
        viewport={{once: false, amount: 0.3}}
    className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <FeatureTwo title={item.title} text={item.text} key={item.title + index} />
      ))}
    </motion.div>
  </div>
);

export default Features;
